/* eslint-disable class-methods-use-this */
import {
  getModule,
  Action,
  Module,
  VuexModule,
} from 'vuex-module-decorators'
import { store } from '@/store'
import axios from '@/libs/axios'

const EMAIL_STORE_MODULE_NAME = 'app-email'

@Module({
  store,
  dynamic: true,
  namespaced: true,
  name: EMAIL_STORE_MODULE_NAME,
})
class EmailStore extends VuexModule {
  @Action
  public async fetchEmails(payload: any) {
    return new Promise((resolve, reject) => {
      axios
        .get('/apps/email/emails', { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  public async updateEmail(payload: any) {
    return new Promise((resolve, reject) => {
      axios
        .post('/apps/email/update-emails', payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  public async updateEmailLabels(payload: any) {
    return new Promise((resolve, reject) => {
      axios
        .post('/apps/email/update-emails-label', payload)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  @Action
  public async paginateEmail(payload: any) {
    return new Promise((resolve, reject) => {
      axios
        .get('/apps/email/paginate-email', { params: payload })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export const loadEmailStore = () => {
  if (!store.hasModule(EMAIL_STORE_MODULE_NAME)) {
    store.registerModule(EMAIL_STORE_MODULE_NAME, EmailStore)
  }

  return getModule(EmailStore, store)
}

export const unloadEmailStore = () => {
  if (store.hasModule(EMAIL_STORE_MODULE_NAME)) {
    store.unregisterModule(EMAIL_STORE_MODULE_NAME)
  }
}
